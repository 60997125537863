function renderVideoPlayButton(){
  if ($('.fancybox-media').length) {
    var $videobuttons = $('.fancybox-media');
    $videobuttons.each(function(idx){
      $(this).append('<svg class="video-overlay-play-button" viewBox="0 0 200 200" alt="Play video">\
              <circle cx="100" cy="100" r="90" fill="none" stroke-width="15" stroke="#fff"/>\
              <polygon points="70, 55 70, 145 145, 100" fill="#fff"/>\
          </svg>');
    });
  }
}

function addLoadMoreButton() {
    var numDisplayedNewsItems = $('.news-item').length;
    var maxNewsItems = $('#total-news-items').val();
    var $loadMoreHolder = $('#load-more-holder');
    var $button = $('<button class="load-more small button" data-current-page="1" data-parent="9">Load more</button>');

    if ( numDisplayedNewsItems < maxNewsItems ) {
        $loadMoreHolder.append($button);
    }
}

$(document).ready(function(e) {
  $(document).foundation();
  renderVideoPlayButton();
  addLoadMoreButton();

  $('body').on("click", '#cm-reset', function() {
		$('#cm-result').fadeOut( function(){ $('#cm-form').fadeIn(); } );
	});

	var content = '';
	$('#subForm').submit(function (e) {
		e.preventDefault();
		$.getJSON(
			this.action + "?callback=?",
			$(this).serialize(),
			function (data) {
				if (data.Status === 400) {
					content = '<h3>Error :(</h3><p>' + data.Message + '</p><p id="cm-reset" class="jslink">&lt; Try again</p>';
				} else { // 200
					content = '<h3>Success!</h3><p>' + data.Message + '</p>';
				}
				$('#cm-form').fadeOut( function(){ $('#cm-result').html(content).fadeIn(); } );
		});
	});

	$('a.youtube').click( function() {
		window.open( $(this).attr('href') );
		return false;
	});

	$('.fancybox-media').fancybox({
		openEffect  : 'none',
		closeEffect : 'none',
		helpers : {
			media : {}
		}
	});

	$('.news').on('click', '.load-more', function(e) {
		e.preventDefault();
	
		var $this = $(this);
	
		var load   = $this.data('current-page') + 1;
		var parent = $this.data('parent');
	
		$.ajax({
			type: "GET",
			url: "/api/paginate",
			data: {page: load, parent: parent},
			success: function (response) {
				response = $.parseJSON(response);
	
				if(response.success) {
					$(".news-summary--list").append(response.page);
	
					// Update the page attribute
					$this.data('current-page', load);
					// Foundation.reInit('equalizer');
				} else {
					$this.after(response.page);
					$this.remove();
				}
			},
			error: function (jqXHR, textStatus, errorThrown) {
				// Do something if there was an error
			}
		});
	});	

  	retinajs();

	if ( $('.vacancy-application-form').length ) {
		// saveApplicationFormProgress();
		const app = new parklandsApplicationForm();
		app.populateForm();
	}

	if ( $('.application-submitted').length ) {
		// submitted, so clear the saved data
		const app = new parklandsApplicationForm();
		app.clearFormData(); 
	}
});



var parklandsApplicationForm = function() {
	const applicationForm = document.querySelector('.vacancy-application-form');
	
	const formIdentifier = applicationForm.id; // Identifier used to identify the form

	const saveButton = document.querySelector("#application-form-save"); // select save button
	const clearButton = document.querySelector('#application-form-clear');
	const alertBox = document.querySelector(".save-alert"); // select alert display div
	let formElements = applicationForm.elements; // get the elements in the form	



	saveButton.onclick = event => {
		event.preventDefault();
		saveFormData();
		const message = "Your application form draft has been saved!";
		displayAlert(message);	
	};

	clearButton.onclick = event => {
		event.preventDefault();
		clearFormData();
		displayAlert("Your application form draft has been deleted.");
		console.log("Cleared saved form data for " + formIdentifier);
		
	}


	/**
	 * This function gets the values in the form
	 * and returns them as an object with the
	 * [formIdentifier] as the object key
	 * @returns {Object}
	 */
	const getFormData = () => {
		let data = { [formIdentifier]: {} }; // create an empty object with the formIdentifier as the key and an empty object as its value
		for (const element of formElements) {
			if (element.name.length > 0) {
				if ( element.type == 'radio' || element.type=='checkbox' ) {
					// get value of checked element
					let cbName = element.id;
					let cb = document.querySelector('input[id="' + cbName + '"]:checked');

					if (cb !== null) {
						data[formIdentifier][element.id] = cb.value;
					} else {
						data[formIdentifier][element.id] = null;
					}

				} else if ( element.type == 'text' || element.type == 'textarea' || element.type == 'email' ) {
					data[formIdentifier][element.id] = element.value;
				}
			}
		}
		return data;
	};

	function clearFormData() {
		localStorage.removeItem(formIdentifier);
	}

	function saveFormData() {
		data = getFormData();
		localStorage.setItem(formIdentifier, JSON.stringify(data[formIdentifier]));
	}

	

	/**
	 * This function displays a message
	 * on the page for 1 second
	 *
	 * @param {String} message
	 */
	const displayAlert = message => {
		alertBox.innerText = message; // add the message into the alert box
		alertBox.style.display = "block"; // make the alert box visible
		setTimeout(function () {
			alertBox.style.display = "none"; // hide the alert box after 1 second
		}, 1000);
	};

	/**
	* This function populates the form
	* with data from localStorage
	*
	*/
	function populateForm() {
		if (localStorage.key(formIdentifier)) {
			const savedData = JSON.parse(localStorage.getItem(formIdentifier)); // get and parse the saved data from localStorage
			if ( savedData !== null ) {
				for (const element of formElements) {
					if (element.id in savedData) {

						if ( element.type == 'checkbox' || element.type == 'radio' ) {
							// set the matching checkbox with value to checked
							let cbName = element.id;
							if ( savedData[element.id] != null ) {
								let cb = document.querySelector('input[id="' + cbName + '"][value="' + savedData[element.id] + '"]');
								if ( cb !== null ) {
									cb.checked = true;
								} else {
									console.log('Form element with saved value not found: input[id="' + cbName + '"] with value ' + savedData[element.id]);
								}
							}
						} else if ( element.type == 'text' || element.type == 'textarea' || element.type == 'email' ) {
							element.value = savedData[element.id];
							// console.log(element.id);
						} else {
							console.log("Unhandled element type " + element.type);
						}
					}
				}
				const message = "Your application form has been refilled with saved data.";
				displayAlert(message);
			} else {
				console.log("No saved data found");
			}
		}
	};

	return {
		populateForm:populateForm,
		clearFormData:clearFormData,
		saveFormData:saveFormData
	}
}
